import { Box, Grid, Hidden, Link, Typography } from '@mui/material';
import { theme } from '@care/material-ui-theme';
import { FC, useState } from 'react';
import Image, { StaticImageData } from 'next/image';
import ModalVideo from '@/components/pages/shared/ModalVideo/ModalVideo';
import { PlayButtonLink } from '@/components/ui';
import { GOOGLE_REVIEWS_URL, HOW_HP_HELPS_VIDEO_URL, PAGE_PROPERTIES } from '@/constants';
import { useMobileQuery } from '@/utils/hooks';
import { AmplitudeEventName, logAnalyticsEvent } from '@/utils/AnalyticsHelper';
import { Review } from '@/components/ui/types';
import QuotesIcon from '../../../../assets/images/quotes.svg';
import ClientReviews from './ClientReviews';
import fiveRating from '../../../../assets/images/fiverating.png';

interface TestimonialsProps {
  title: string;
  imageURI: StaticImageData;
  reviews: Review[];
  hasClientStory?: boolean;
}

const Testimonials: FC<TestimonialsProps> = ({
  title,
  imageURI,
  reviews,
  hasClientStory = true,
}) => {
  const [isVisible, setVisibility] = useState(false);
  const isMobile = useMobileQuery();

  const toggleModalVisibility = () => setVisibility((_isVisible) => !_isVisible);

  const handleVideoButtonClick = () => {
    toggleModalVisibility();
    logAnalyticsEvent({
      data: {
        cta_location: 'HomePay VHP',
        cta_text: 'Watch a Client Story',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: PAGE_PROPERTIES.mainVHP,
    });
  };

  const handleReviewsClick = () => {
    logAnalyticsEvent({
      data: {
        cta_location: 'Reviews',
        cta_text: 'ReadMoreReviewOnGoogle',
      },
      name: AmplitudeEventName.ctaInteracted,
      pageProperties: PAGE_PROPERTIES.mainVHP,
    });
  };

  return (
    <Grid container spacing={0} maxWidth="xl">
      <Grid
        sx={{
          '& span': {
            overflow: 'unset !important',
          },
          position: 'relative',
          width: '100%',
        }}
        item
        md={5}
        sm={12}>
        <Box
          sx={{
            borderRadius: [0, 0, 2],
            [theme.breakpoints.up('md')]: {
              bottom: 'unset !important',
              left: 'unset !important',
              position: 'absolute',
              right: '-68px !important',
              top: '-48px !important',
              zIndex: 100,
            },
          }}>
          <Image
            alt="Family depends"
            data-testid="testimonials-left-image"
            height={!isMobile ? 580 : 316}
            layout={isMobile ? 'responsive' : 'intrinsic'}
            loading="lazy"
            src={imageURI}
            width={!isMobile ? 568 : 375}
          />
        </Box>
        <Box
          sx={{
            '& img': {
              left: '50% !important',
              top: '50% !important',
              transform: 'translate(-50%, -25%)',
            },
            [theme.breakpoints.up('md')]: {
              borderRadius: theme.spacing(6),
              bottom: 'unset',
              height: '100px',
              left: 'unset',
              right: '-105px',
              top: '180px',
              transform: 'translateX(0%)',
              width: '100px',
            },
            alignItems: 'center',
            backgroundColor: theme.palette.care.yellow[100],
            borderRadius: theme.spacing(5),
            bottom: '-28px',
            display: 'flex',
            height: theme.spacing(8),
            justifyContent: 'center',
            left: '50%',
            position: 'absolute',
            transform: 'translateX(-50%)',
            width: theme.spacing(8),
            zIndex: 101,
          }}>
          <QuotesIcon
            width={isMobile ? 55 : 80}
            height={isMobile ? 50 : 75}
            data-testid="testimonials-quotes-image"
          />
        </Box>
      </Grid>
      <Grid
        sx={{
          width: '100%',
        }}
        item
        md={7}
        sm={12}>
        <Box
          sx={{
            '& .Testimonials--google-url': {
              [theme.breakpoints.up('md')]: {
                alignItems: 'center',
                display: 'flex',
              },
            },
            '& h2': {
              fontSize: '28px',
              fontWeight: 'bold',
              lineHeight: '34px',
              margin: theme.spacing(0),
              paddingBottom: theme.spacing(2),
              [theme.breakpoints.up('md')]: {
                fontSize: '36px',
                lineHeight: '42px',
                margin: theme.spacing(0),
              },
            },
            [theme.breakpoints.up('md')]: {
              borderRadius: theme.spacing(2),
              height: 'auto',
              minHeight: 600,
              marginRight: 0,
              padding: theme.spacing(7, 4, 6, 16),
              textAlign: 'left',
            },
            backgroundColor: theme.palette.care?.grey[50],
            padding: theme.spacing(5, 0),
            textAlign: 'center',
          }}>
          <Typography data-testid="testimonials-heading" variant="h2">
            {title}
          </Typography>
          <ClientReviews reviews={reviews} />
          <Box mt={5}>
            <Link
              className="Testimonials--google-url"
              data-testid="testimonials-google-url"
              href={GOOGLE_REVIEWS_URL}
              onClick={handleReviewsClick}
              target="_blank">
              <Image
                alt="More reviews"
                height={19}
                layout="fixed"
                loading="lazy"
                src={fiveRating}
                width={128}
              />
              <Hidden smUp>
                <br />
              </Hidden>
              <Box component="span" pl={1}>
                Read more reviews on Google &gt;
              </Box>
            </Link>
          </Box>
          {hasClientStory && (
            <>
              <Box mt={4}>
                <PlayButtonLink data-testid="watch-story-link" onClick={handleVideoButtonClick}>
                  Watch a client story
                </PlayButtonLink>
              </Box>
              <ModalVideo
                dataTestId="homepage-how-homepay-helps-modal"
                isVisible={isVisible}
                onClose={toggleModalVisibility}
                title="How Care.com HomePay helps"
                videoUrl={HOW_HP_HELPS_VIDEO_URL}
              />
            </>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Testimonials;
